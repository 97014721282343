@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap);
html {
    -webkit-text-size-adjust: none;
    -webkit-appearance: none;
}

html,
body {
    min-width: 330px;
    background: #f5f5f5;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
    padding: 0;
    color: #000101;
    line-height: 1.2;
    height: 100vh;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

a {
    cursor: pointer;
}

input,
textarea,
select,
button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #000101;
    -webkit-appearance: none;
    outline: 0;
    margin: 0;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #999;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #999;
    -moz-transition: color 0.3s;
    transition: color 0.3s;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
    color: #eee;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
    color: #eee;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
}

.main {
    margin-top: 100px;
    flex: 1 1;
}

.site-width {
    margin: 0 auto;
    padding: 0 10px;
    max-width: 1240px;
    box-sizing: border-box;
}

.nowrap {
    white-space: nowrap;
}

.button {
    font-size: 20px;
    display: inline-block;
    padding: 20px 30px;
    color: #fff;
    border: #4d697e 2px solid;
    background: #4d697e;
    border-radius: 10px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    outline: 0;
    cursor: pointer;
}

.button:hover {
    background: #5681a1;
    border-color: #5681a1;
    color: #fff;
}

.button:focus {
    background: #f99f1f;
    border-color: #f99f1f;
    color: #fff;
}

.button[disabled],
.button[disabled]:hover {
    background: #fff;
    color: #4d697e;
    cursor: default;
}

button {
    cursor: pointer;
    outline: 0;
}

button[type='submit'],
button.submit {
    font-size: 24px;
    display: inline-block;
    padding: 15px 30px;
    color: #fff;
    font-weight: 600;
    border: #f99f1e 1px solid;
    background: #f99f1e;
    border-radius: 30px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    outline: 0;
    cursor: pointer;
}
button[type='submit']:disabled,
button.submit:disabled {
    background: #a3a3a3;
    border-color: #a3a3a3;
    cursor: default;
}

header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    background: #f5f5f5;
}

header .site-width {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
}

header .left {
    display: flex;
    align-items: center;
    margin-right: 40px;
}

header .logo {
    display: block;
}

header .logo img {
    display: block;
    max-width: 150px;
    max-height: 50px;
}

header .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

header .nav-opener {
    display: none;
    margin: 0 15px;
    width: 20px;
    height: 10px;
    position: relative;
    border-top: #232323 2px solid;
    border-bottom: #232323 2px solid;
    box-sizing: border-box;
}

header nav {
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-height: 32px;
}

header nav + nav {
    border-left: #515151 1px solid;
    margin-left: 15px;
    padding-left: 15px;
}

header nav a,
header nav span {
    color: #000101;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

header nav a:hover {
    text-decoration: underline;
}

header nav .item {
    margin: 0 15px;
}

header nav .item a {
    display: flex;
    align-items: center;
}

header nav .item .item-login:after {
    display: block;
    margin-left: 10px;
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    content: '';
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAC8UlEQVRIib2WXWiOYRjHf+/TDmRmaT4jYSvLx1ZEL1uIWlk5MEucSMPkBMm3Ew4kR8iJhgM5YNIcqBEitdmyWphmEQ44UDP1GrGo6er5P553z32/7xz519tb13Xd/+u+r88nVV9fTx6kgGVAHVANlAPFMs8AfUAb0AJ0AMO5qAJHEqMW6BZRGrgLNACr9GuQLC2bbp3xosAjLAKagI3AZb3mvWMVwl5yHJgNHAFuAzeARmAw2zDpaCpwDyjUTbscahgHzAeWA5+BW7qIkV8ErgPtQA3wyRe6IjkZ8jipBB4CP3XTTmAncAboAabLrktnh8RV5HPUpJdYnPuz5JOBR8B3hXGJZFYYs4AB4HyWfb84CsU5InS1ykk64cSwRRW2Hvid0H0DDqsoxgNfs5xt0suvAq2BSvikEu/LyVLgvsdJhHZxLEzIu8Rp3KkC9UmlwuJDCdDrkUf4obyVOBo4Bbw1H4EcPMlTwlYAYx2pi5QjCTmNuy5Qxz9wTGJ8ASY60hgp5SfjaEIYd3Wg6nnhqGNY2BY50hhz5OyDowlh3OWBZteAo45hpV0hQh9qVGXvPDrEXZxv1kXo1Kv2OJqwPfYBV/INVNSwmVFyYDgB7AJmJuTbNbZOOydiGHcm0KivcNQjcRN4A+xIyPcD5zTzcsG4+wKN+DU5jCIMy25eQl6qHOaDcbcFGvVVQFke4xnAWuBZQm47aC8wxjkRokzcLYE2oxEccsxgikbIK+AjcDah3wos1gTf4JwOOY27I1BYjgHbNFRRNR3V+LDheABYmVxmcmAz7o72kP1Pki4tTuMejsq7FWgGrmmpWdwPqnTnAheAX859Q1if7Na8tL30FFghrmZxj9hH0fp9qS1aqX2Sa2on0aut+xp4LK7GyCbb0aC63MIxAZjmUI2OBYpAj7gGfY7Qjq9SgbTrG6D0HxyYzSWdsbPG8fd7weD7CrJbbNZmtIqzRjUCm8IW1qg5reOtGVeL+DmwLspJEv/nAxL4A/TAsmOMOcA8AAAAAElFTkSuQmCC');
}

.scrolled header {
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
    background: #f5f5f5;
}

.scrolled header .site-width {
    padding-top: 10px;
    padding-bottom: 10px;
}

@media (max-width: 900px) {
    header .site-width {
        padding: 10px;
    }
    header .nav-opener {
        display: block;
    }
    header .nav-opener + nav {
        display: none;
    }
    header .nav-active + nav {
        display: block;
        position: absolute;
        top: 100%;
        right: 30px;
        background: #f5f5f5;
    }
    header .nav-active + nav .item {
        margin: 20px;
    }
    header .nav-active {
        border: 0;
    }
    header .nav-active:before,
    header .nav-active:after {
        display: block;
        position: absolute;
        top: calc(50% - 1px);
        width: 20px;
        height: 2px;
        background: #232323;
        content: '';
    }
    header .nav-active:before {
        -webkit-transform: rotateZ(45deg);
                transform: rotateZ(45deg);
    }
    header .nav-active:after {
        -webkit-transform: rotateZ(-45deg);
                transform: rotateZ(-45deg);
    }
}

.top-block {
    margin-bottom: 25px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.top-block .slogan {
    background: #f99f1e;
    border-radius: 20px;
    color: #fff;
    padding: 60px 40px 60px 50px;
    position: relative;
}

.top-block .slogan-title {
    font-size: 42px;
    font-weight: bold;
}

.top-block .slogan-title i {
    font-style: normal;
    color: #f99f1e;
    position: relative;
    display: inline-block;
    padding: 0 10px;
}

.top-block .slogan-title i span {
    position: relative;
}

.top-block .slogan-title i:before {
    background: #fff;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    -webkit-transform: skewX(-10deg);
            transform: skewX(-10deg);
    content: '';
}

.top-block .slogan-more {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    position: absolute;
    bottom: 35px;
    right: 40px;
    padding: 0 15px 10px 0;
}

.top-block .slogan-more:before {
    display: block;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 20px;
    background: #fff;
    content: '';
}

.top-block .slogan-more:after {
    display: block;
    width: 14px;
    height: 13px;
    position: absolute;
    bottom: -5.5px;
    right: 0;
    content: '';
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAANCAYAAACZ3F9/AAAA5UlEQVQokZWSvU5CQRCFP3+idlhIYeUrEBuIT8AD+Cb2NrQ0PIONNhbEB9CGzpYKCkJBjCZqhMQYwmdW9+rm3guBk8xmMyczZ/bsoOajpi7Udgn3F4WE2vQXc/WkhP+JbYoYx8wOcFFgM6jX6lnSreI/pupRmWI43tS+upcQH0nxZVnhlvoInAI94AWoAA1gPw41AwbJkAvgNlR33Ryfu8Bh7DQCnqNCHTiI+XfgPlF8Be6C4lB9iqZkb5gkM7SWmfOgnueI8IcBX+rxugsQ7M9wtckCVJN7Z9UC5KMaXFNvlu6q8g3x0rMymdsOMwAAAABJRU5ErkJggg==');
}

.top-block .steps {
    padding: 30px 30px 25px;
    display: flex;
    justify-content: space-between;
}

.top-block .step {
    display: flex;
    flex-direction: column;
}

.top-block .step-img {
    flex-shrink: 0;
    height: 125px;
    margin-bottom: 10px;
}

.top-block .step-img a {
    display: block;
}

.top-block .step-img img {
    height: 125px;
    width: 200px;
    display: block;
    margin: 0 auto;
}

.top-block .step-text {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
}

.top-block .step-num {
    margin-right: 15px;
    font-size: 34px;
    color: #f99f1e;
    font-weight: bold;
}

@media (max-width: 900px) {
    .top-block {
        margin-top: 56px;
    }
    .top-block .slogan {
        padding: 20px;
    }
    .top-block .slogan-title {
        font-size: 30px;
    }
    .top-block .slogan-more {
        display: none;
    }
    .top-block .steps {
        padding: 20px 10px;
    }
    .top-block .step {
        margin: 0 10px;
    }
    .top-block .step-img {
        display: none;
    }
    .top-block .step-text {
        width: auto;
    }
    .top-block .step-num {
        margin-right: 5px;
    }
}
@media (max-width: 600px) {
    .top-block .slogan-title {
        font-size: 20px;
    }
    .top-block .steps {
        flex-wrap: wrap;
    }
    .top-block .step {
        width: calc(50% - 20px);
    }
    .top-block .step-text {
        justify-content: flex-start;
    }
    .top-block .step-text br {
        display: none;
    }
}
@media (max-width: 450px) {
    .top-block .step {
        width: calc(100% - 20px);
    }
}

.search-block {
    display: flex;
    margin-bottom: 25px;
}

.search-block .news-block {
    width: 200px;
    margin-left: 25px;
    box-sizing: border-box;
    flex-shrink: 0;
}

.search-block .form-block {
    flex-grow: 1;
    width: calc(100% - 225px);
}

.news-block {
    padding: 20px 12px 15px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.news-block .title {
    font-size: 18px;
    text-align: center;
}

.news-block .item {
    margin-top: 40px;
    color: #000101;
    text-decoration: none;
    font-size: 11px;
    display: block;
    line-height: 1.5;
}

.news-block .item-more {
    font-size: 12px;
    font-weight: 600;
    display: inline-block;
    margin-top: 15px;
    padding-bottom: 4px;
    border-bottom: #a3a3a3 1px solid;
}

@media (max-width: 1200px) {
    .news-block {
        display: none;
    }
}

.form-block {
    display: flex;
    flex-direction: column;
}

.form-block .tabs-head {
    display: flex;
}

.form-block .tabs-head .tab {
    flex-grow: 1;
    position: relative;
    margin: 0 8px;
    padding: 20px;
    background: #fafafa;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
    border-radius: 20px 20px 0 0;
}

.form-block .tabs-head .tab:first-child {
    margin-left: 0;
}

.form-block .tabs-head .tab:last-child {
    margin-right: 0;
}

.form-block .tabs-head .tab.tab-active {
    background: #fff;
    z-index: 2;
}

.form-block .tabs-body {
    flex-grow: 1;
    padding: 30px 40px;
    border-radius: 0 0 20px 20px;
    background: #fff;
    position: relative;
    z-index: 2;
}

.form-block .tabs-body .tab {
    display: none;
}

.form-block .tabs-body .tab.tab-active {
    display: block;
}

.form-block .filter label {
    font-size: 16px;
    display: block;
}

.form-block .filter .filter-control label {
    height: 19px;
    z-index: 1;
    position: relative;
}
.form-block .filter .filter-airports {
    display: flex;
    margin-bottom: 35px;
    position: relative;
}

.form-block .filter .filter-airport {
    width: 33%;
    position: relative;
}

.form-block .filter .filter-airport label {
    margin-bottom: 20px;
}

.form-block .filter .filter-airport-to {
    text-align: right;
}

.form-block .filter .filter-airport-name {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    outline: none;
    border-bottom: #a3a3a3 1px solid;
}

.form-block .filter .filter-airport-code {
    font-size: 18px;
    color: #949494;
}

.form-block .filter .filter-airport-hint {
    position: absolute;
    top: 30px;
    left: 0;
    display: none;
    min-width: 100%;
    text-align: center;
    padding: 10px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
    box-sizing: border-box;
}

.form-block .filter .filter-airport-name:hover ~ .filter-airport-hint,
.form-block .filter .filter-airport-hint:hover {
    display: block;
}

.form-block .filter .filter-track {
    flex-grow: 1;
    position: relative;
    margin: 50px 15px 0;
    height: 1px;
    background: #a3a3a3;
}

.form-block .filter .filter-track:before,
.form-block .filter .filter-track:after {
    position: absolute;
    top: -4.5px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #a3a3a3;
    content: '';
}

.form-block .filter .filter-track:before {
    left: 0;
}

.form-block .filter .filter-track:after {
    right: 0;
}

.form-block .filter .filter-track i {
    display: block;
    width: 50px;
    height: 34px;
    margin: -16.5px auto 0;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAiCAYAAADYmxC7AAACPElEQVRYhc2XO2gUQRyHP88zPtAQjAYFFe1MkVrUQsFCSGEgNoLYaaP4KhQDgim0FJ+FGAQ7C6OVdhYSQbG0EcFHoaIgik8sBI382Qk3t7/Zu525Y5MPDm6//c/yu5m5mdl50zeJpQ+YAoaAH8A14BQwHf2kAmph3ZJRF8joBU66UF0jJdR6MXAaWCE2kZRQ88XAEuCw2ApDvROTcQhYKjaBbobqBw6IrSjUezENjgM9YisI9VZMg7XAXrEJodYBe4AdcjfMV+B38E7GicQf2xTqGXALeAAckYowRfPKGAR2iY0MVfeuz7kh6CQUnS6mFuqVd21/6QtSpbSa7MYmYJvYiFBvcm43MCyVzbQLZYyJKYkN3etA6VVgC7BI7sCykhN5J7Af+Oy5hcBiqcywznlkG3s9N3wzbAA+io1nIrLFE2CkVtBTs8Vm4MZcC2UMW6hfomeXLxZq9RwLNW4TfY3oarEj9T/gBXAemKy7vS/PX+Ag8Mnzdgb/7r5vB85IK2Ur8Nyztmf+kaocRT11CbgutsFGMcpD4LHYEtQCoT7YuLZpOiBGKbNdBQmFOgb8DBV7rBLTzEvgntiIUP3etR1fbkuV0u7N5bKbvEnYnDoL7HONj5Z8SKue+gbEv+J6WKi77hNDqzk10emCnHpsXSkmw5aSK2IjSQm1AFguNmOyxKm0LSmhinqJTpYBn26GsoXyqdiKQhVxscBXEsqOrfk1yHrpTpcyJYWy1d56xTZo+7fddy8byYtlE8B/EeRYOTB09x4AAAAASUVORK5CYII=');
}

.form-block .filter .filter-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
}

.form-block .filter .filter-control {
    width: 30%;
}

.form-block .filter .filter-control label {
    margin-bottom: 5px;
}

.form-block .filter .filter-control input,
.form-block .filter .filter-control .custom-input {
    text-align: center;
    width: 100%;
    display: block;
    outline: none;
}

.form-block .filter input[type='number']::-webkit-outer-spin-button,
.form-block .filter input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.form-block .filter input[type='number'] {
    -moz-appearance: textfield;
    cursor: text;
    width: 100%;
}

.form-block .filter input[type='number']:hover,
.form-block .filter input[type='number']:focus {
    -moz-appearance: number-input;
}

.form-block .filter .filter-checkbox {
    margin-bottom: 35px;
}

.form-block .filter .filter-bottom {
    display: flex;
    justify-content: space-between;
}

.form-block .filter .filter-links > div {
    display: flex;
    align-items: center;
}

.form-block .filter .filter-links > div + div {
    margin-top: 10px;
}

.form-block .filter .filter-links a {
    font-size: 11px;
    font-weight: 400;
    color: #000101;
    text-decoration: none;
}

.form-block .filter .filter-links a:hover {
    text-decoration: underline;
}

.form-block .filter .filter-links .icon {
    display: block;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f5f5f5;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.form-block .filter .filter-links .icon-rules {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAPCAYAAAA2yOUNAAAAzUlEQVQokX3PsUqCYRjF8Z8fQQQNIQVFga4tQjeR4FLEtzoYRBfRoDU31Bw0BE0NXoJCDtIQQkvS3lxQQ7jES28pbx+e8Zw/zzlPKc9zWEMHe9jEF+5xisEC1jGM4S0esYUj9NAIUBsVHKBrqiuMcJ5hH08JEPSCPmpZ7B8q1gbeQl21MOYQO7jM/kU/asVNDzgpgpq4jhN28RnqZrWIC4xRx0cw00vbKOPuFyiClvGO11kzrRtgJR2ZXlqKw1fnQce4wdk8KHw1wfOfg2+SySWHhi2aPwAAAABJRU5ErkJggg==');
}

.form-block .filter .filter-links .icon-oferta {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAPCAYAAADQ4S5JAAAA5UlEQVQokYXSvS4FYRDG8d8eW7oAlUYvolJKuIBTbKehdgNqrkEtQSsUTiORaLQkopBoRKVDolH4yMS7MTm7jid5m5n5z8c7UzVNc4QVfOlqCu9Yw1l4ayxjAS+dcO4wwj5WcRvAJx46ob/aKYlPsTRIjk08l0pzY9A67qOtOhl3cYgPvBXbE65TTJ2B2ZIp6xjTOMBjVMnAYvmtPl3ipv2lViflTVQGhtjo2UeFvTZZBq4w/0f28P1MnYwx1PZ/LfXtIb+tcaBvD9Fzq865BBBVZsqSXid0EzGDAC7KkfVda1aF829BSi/PyKlRfQAAAABJRU5ErkJggg==');
}

.form-block .filter button[type='submit'] {
    min-width: 250px;
}

.form-block .filter #return-flight {
    display: none;
}
.form-block .filter #return-flight.return-flight-active {
    display: flex;
}
@media (max-width: 900px) {
    .form-block .tabs-head .tab {
        padding: 10px;
    }
    .form-block .tabs-body {
        padding: 30px;
    }
    .form-block .filter .filter-airport-name {
        width: 100%;
    }
    .form-block .filter .filter-airports,
    .form-block .filter #return-flight.return-flight-active {
        display: block;
    }
    .form-block .filter .filter-airport {
        width: auto;
    }
    .form-block .filter .filter-airport label {
        margin-bottom: 10px;
    }
    .form-block .filter .filter-airport label:after {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        width: 32px;
        height: 22px;
        content: '';
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAiCAYAAADYmxC7AAACPElEQVRYhc2XO2gUQRyHP88zPtAQjAYFFe1MkVrUQsFCSGEgNoLYaaP4KhQDgim0FJ+FGAQ7C6OVdhYSQbG0EcFHoaIgik8sBI382Qk3t7/Zu525Y5MPDm6//c/yu5m5mdl50zeJpQ+YAoaAH8A14BQwHf2kAmph3ZJRF8joBU66UF0jJdR6MXAaWCE2kZRQ88XAEuCw2ApDvROTcQhYKjaBbobqBw6IrSjUezENjgM9YisI9VZMg7XAXrEJodYBe4AdcjfMV+B38E7GicQf2xTqGXALeAAckYowRfPKGAR2iY0MVfeuz7kh6CQUnS6mFuqVd21/6QtSpbSa7MYmYJvYiFBvcm43MCyVzbQLZYyJKYkN3etA6VVgC7BI7sCykhN5J7Af+Oy5hcBiqcywznlkG3s9N3wzbAA+io1nIrLFE2CkVtBTs8Vm4MZcC2UMW6hfomeXLxZq9RwLNW4TfY3oarEj9T/gBXAemKy7vS/PX+Ag8Mnzdgb/7r5vB85IK2Ur8Nyztmf+kaocRT11CbgutsFGMcpD4LHYEtQCoT7YuLZpOiBGKbNdBQmFOgb8DBV7rBLTzEvgntiIUP3etR1fbkuV0u7N5bKbvEnYnDoL7HONj5Z8SKue+gbEv+J6WKi77hNDqzk10emCnHpsXSkmw5aSK2IjSQm1AFguNmOyxKm0LSmhinqJTpYBn26GsoXyqdiKQhVxscBXEsqOrfk1yHrpTpcyJYWy1d56xTZo+7fddy8byYtlE8B/EeRYOTB09x4AAAAASUVORK5CYII=');
    }
    .form-block .filter .filter-airport-to {
        text-align: left;
        margin-top: 30px;
    }
    .form-block .filter .filter-track {
        height: 124px;
        width: 1px;
        position: absolute;
        top: 43px;
        left: -15px;
        margin: 0;
    }
    .form-block .filter .filter-track:before {
        left: -4.5px;
        top: 0;
    }
    .form-block .filter .filter-track:after {
        left: -4.5px;
        right: auto;
        bottom: 0;
        top: auto;
    }
    .form-block .filter .filter-track i {
        display: none;
    }
    .form-block .filter .filter-controls {
        display: block;
    }
    .form-block .filter .filter-control {
        width: 100%;
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;
    }
    .form-block .filter .filter-control label {
        border-bottom: #a3a3a3 1px solid;
        margin-bottom: 0;
        padding-bottom: 5px;
        flex-grow: 1;
        height: auto;
    }
    .form-block .filter .filter-control input {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .form-block .filter .filter-control .control {
        /* max-width: 250px; */
    }
}
@media (max-width: 600px) {
    .form-block .filter .filter-bottom {
        display: block;
    }
    .form-block .filter .filter-submit {
        margin-bottom: 20px;
        text-align: center;
    }
    .form-block .filter .filter-links {
        display: flex;
        flex-wrap: wrap;
    }
    .form-block .filter .filter-links > div {
        margin-right: 20px;
    }
    .form-block .filter .filter-links > div + div {
        margin-top: 0;
    }
}
@media (max-width: 900px) {
    .form-block .filter .filter-control {
        display: block;
    }
    .form-block .filter .filter-control label {
        border-bottom: 0;
    }
}

.attention-block {
    font-size: 11px;
    margin: 10px 0 10px 20px;
}

.sort-block {
    display: flex;
    align-items: center;
    padding: 25px 30px;
    margin-bottom: 30px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.sort-block .title {
    font-size: 16px;
    margin-right: 30px;
    white-space: nowrap;
}

.sort-block .control-button {
    min-width: 200px;
}

@media (max-width: 1010px) {
    .sort-block .control-button {
        min-width: 0;
    }
}

@media (max-width: 800px) {
    .sort-block {
        display: block;
        padding: 20px;
    }
    .sort-block .title {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .sort-block .title:nth-of-type(n + 2) {
        margin-top: 20px;
    }
}
@media (max-width: 400px) {
    .sort-block .control-button {
        font-size: 13px;
        padding: 5px 10px;
    }
}

.flight {
    margin-bottom: 25px;
}

.flights-block {
    margin-bottom: 30px;
}

.flights-block .flights-head .flight-table {
    width: calc(100% - 110px);
    padding: 5px 20px;
    box-sizing: border-box;
}

.flights-block .flights-body {
    display: flex;
    border-radius: 20px;
    background: #d4d4d4;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.flights-block .flights-body .flight-table {
    width: calc(100% - 130px);
    padding: 5px 20px;
    border-radius: 20px;
    background: #fff;
    box-sizing: border-box;
}

.flights-block .flights-body .flight-select {
    width: 130px;
    padding: 15px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
}

.flights-block .flights-body:hover {
    background: #f99f1e;
}

.flights-block .flights-summary {
    padding: 15px;
    padding-right: 150px;
    font-size: 14px;
    text-align: right;
    margin-bottom: -25px;
}

.flights-block table {
    border-collapse: collapse;
    width: 100%;
}

.flights-block table tr:last-child td {
    border-bottom: 0;
}

.flights-block table th {
    text-align: center;
    vertical-align: top;
    font-size: 10px;
    font-weight: 500;
    padding: 15px 10px;
}

.flights-block table td {
    border-bottom: #c5c5c5 1px solid;
    text-align: center;
    vertical-align: middle;
    font-size: 15px;
    padding: 15px 10px;
}

.flights-block table td small {
    font-size: 12px;
}

.flights-block table .flight-plane-info {
    display: inline-flex;
    align-items: center;
}

.flights-block table .flight-plane-name {
    text-align: left;
    width: 100px;
    overflow: hidden;
}

.flights-block table .flight-plane-img {
    width: 80px;
    margin-left: 10px;
    text-align: center;
}

.flights-block table .flight-plane-img small {
    font-size: 10px;
}

.flights-block table .flight-duration small {
    font-size: 10px;
}

.flights-block table .flight-plane-img img {
    display: block;
    width: 100%;
}

.flights-block table .flight-logo img {
    max-width: 100%;
}

.flights-block table .flight-plane {
    width: 170px;
}

.flights-block table .flight-logo {
    width: 90px;
}

.flights-block table .flight-airport {
    width: 115px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flights-block table .flight-airport div {
    width: 115px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: auto;
    margin-right: auto;
}

.flights-block table .flight-date {
    width: 120px;
}

.flights-block table .flight-duration {
    width: 80px;
}

.flights-block table .flight-price {
    width: 100px;
    white-space: nowrap;
}

.currency-selector .button-dropdown {
    min-width: 54px;
}

.currency-selector.control {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}

.currency-selector.control i {
    display: inline-block;
    width: 14px;
    height: 19px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
}
.currency-selector.control i[data-currency='rub'] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.93 25.48'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23494949;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EР РµСЃСѓСЂСЃ 4%3C/title%3E%3Cg id='РЎР»РѕР№_2' data-name='РЎР»РѕР№ 2'%3E%3Cg id='Layer_1' data-name='Layer 1'%3E%3Crect class='cls-1' y='16.88' width='14.26' height='2.48'/%3E%3Cpath class='cls-1' d='M9.62,14.61H0V12.13H9.62a4.83,4.83,0,1,0,0-9.65H3.76V0H9.62a7.31,7.31,0,1,1,0,14.61Z'/%3E%3Crect class='cls-1' x='2.52' y='0.05' width='2.48' height='25.43'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.currency-selector.control i[data-currency='usd'] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.26 28.7'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23494949;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EР РµСЃСѓСЂСЃ 2%3C/title%3E%3Cg id='РЎР»РѕР№_2' data-name='РЎР»РѕР№ 2'%3E%3Cg id='Layer_1' data-name='Layer 1'%3E%3Crect class='cls-1' x='6.39' width='2.48' height='28.7'/%3E%3Cpath class='cls-1' d='M7.7,27l-.63,0C1.63,26.67.2,22.6.2,20.5H2.68c0,.39.18,3.77,4.54,4a5.4,5.4,0,0,0,5-2A3.34,3.34,0,0,0,12.37,19a5.67,5.67,0,0,0-2.71-2.22l-.25-.14L7.07,15.45h0L4.69,14.21l-.25-.13a8,8,0,0,1-3.76-3.3A5.77,5.77,0,0,1,1,4.86,7.85,7.85,0,0,1,8.19,1.7c5.45.33,6.87,4.39,6.87,6.5H12.58c0-.37-.17-3.75-4.54-4A5.42,5.42,0,0,0,3,6.22a3.34,3.34,0,0,0-.15,3.44,5.76,5.76,0,0,0,2.7,2.22l.26.14L8.2,13.25h0l2.37,1.24.24.13a7.92,7.92,0,0,1,3.77,3.3,5.77,5.77,0,0,1-.29,5.92A7.67,7.67,0,0,1,7.7,27Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.currency-selector.control i[data-currency='eur'] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.9 26.58'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23494949;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EР РµСЃСѓСЂСЃ 3%3C/title%3E%3Cg id='РЎР»РѕР№_2' data-name='РЎР»РѕР№ 2'%3E%3Cg id='Layer_1' data-name='Layer 1'%3E%3Crect class='cls-1' y='9.83' width='15.78' height='2.48'/%3E%3Crect class='cls-1' y='14.59' width='15.78' height='2.48'/%3E%3Cpath class='cls-1' d='M14.68,26.58a10.76,10.76,0,0,1-8.23-4,14.35,14.35,0,0,1-3.31-9.33A14.37,14.37,0,0,1,6.45,4a10.8,10.8,0,0,1,8.23-4A10.76,10.76,0,0,1,22.9,4L21,5.57a8.35,8.35,0,0,0-6.34-3.09A8.33,8.33,0,0,0,8.34,5.57a11.84,11.84,0,0,0-2.72,7.72A11.8,11.8,0,0,0,8.34,21a8.31,8.31,0,0,0,6.34,3.1A8.33,8.33,0,0,0,21,21l1.88,1.61A10.73,10.73,0,0,1,14.68,26.58Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

@media (max-width: 1200px) {
    .flights-block.flights-block-lg {
        display: none;
    }
}

.flights-block.flights-block-md,
.flights-block.flights-block-sm {
    display: none;
}
.flights-block.flights-block-md .flight-item,
.flights-block.flights-block-sm .flight-item {
    padding: 10px 30px;
    margin-bottom: 5px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}
.flights-block.flights-block-md .flight-title,
.flights-block.flights-block-sm .flight-title {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
}
.flights-block.flights-block-md .flight-title:before,
.flights-block.flights-block-sm .flight-title:before {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    width: 32px;
    height: 22px;
    content: '';
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAiCAYAAADYmxC7AAACPElEQVRYhc2XO2gUQRyHP88zPtAQjAYFFe1MkVrUQsFCSGEgNoLYaaP4KhQDgim0FJ+FGAQ7C6OVdhYSQbG0EcFHoaIgik8sBI382Qk3t7/Zu525Y5MPDm6//c/yu5m5mdl50zeJpQ+YAoaAH8A14BQwHf2kAmph3ZJRF8joBU66UF0jJdR6MXAaWCE2kZRQ88XAEuCw2ApDvROTcQhYKjaBbobqBw6IrSjUezENjgM9YisI9VZMg7XAXrEJodYBe4AdcjfMV+B38E7GicQf2xTqGXALeAAckYowRfPKGAR2iY0MVfeuz7kh6CQUnS6mFuqVd21/6QtSpbSa7MYmYJvYiFBvcm43MCyVzbQLZYyJKYkN3etA6VVgC7BI7sCykhN5J7Af+Oy5hcBiqcywznlkG3s9N3wzbAA+io1nIrLFE2CkVtBTs8Vm4MZcC2UMW6hfomeXLxZq9RwLNW4TfY3oarEj9T/gBXAemKy7vS/PX+Ag8Mnzdgb/7r5vB85IK2Ur8Nyztmf+kaocRT11CbgutsFGMcpD4LHYEtQCoT7YuLZpOiBGKbNdBQmFOgb8DBV7rBLTzEvgntiIUP3etR1fbkuV0u7N5bKbvEnYnDoL7HONj5Z8SKue+gbEv+J6WKi77hNDqzk10emCnHpsXSkmw5aSK2IjSQm1AFguNmOyxKm0LSmhinqJTpYBn26GsoXyqdiKQhVxscBXEsqOrfk1yHrpTpcyJYWy1d56xTZo+7fddy8byYtlE8B/EeRYOTB09x4AAAAASUVORK5CYII=');
}
.flights-block.flights-block-md
    .flight-item
    + .flight-item
    .flight-title:before,
.flights-block.flights-block-sm
    .flight-item
    + .flight-item
    .flight-title:before {
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
}
.flights-block.flights-block-md table th,
.flights-block.flights-block-sm table th {
    border-bottom: #d3d3d3 1px solid;
    vertical-align: middle;
}
.flights-block.flights-block-md table th.flight-price,
.flights-block.flights-block-sm table th.flight-price {
    padding-top: 5px;
    padding-bottom: 5px;
}
.flights-block.flights-block-md table .flight-airport,
.flights-block.flights-block-md table .flight-airport div,
.flights-block.flights-block-sm table .flight-airport,
.flights-block.flights-block-sm table .flight-airport div {
    width: 250px;
}
.flights-block.flights-block-md .flight-summary,
.flights-block.flights-block-sm .flight-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    margin: 20px 30px;
    white-space: nowrap;
}
.flights-block.flights-block-md .flight-summary button.submit,
.flights-block.flights-block-sm .flight-summary button.submit {
    order: 0;
    background: #a3a3a3;
    border-color: #a3a3a3;
}
.flights-block.flights-block-md .flight-summary div,
.flights-block.flights-block-sm .flight-summary div {
    order: 1;
}
.flights-block.flights-block-md button.submit:hover,
.flights-block.flights-block-sm button.submit:hover {
    background: #f99f1e;
    border-color: #f99f1e;
}
@media (max-width: 1200px) {
    .flights-block.flights-block-md {
        display: block;
    }
}
@media (max-width: 800px) {
    .flights-block.flights-block-md table .flight-airport,
    .flights-block.flights-block-md table .flight-airport div {
        width: 200px;
    }
}
@media (max-width: 700px) {
    .flights-block.flights-block-md table .flight-airport,
    .flights-block.flights-block-md table .flight-airport div {
        width: 120px;
    }
}
@media (max-width: 600px) {
    .flights-block.flights-block-md {
        display: none;
    }
    .flights-block.flights-block-sm {
        display: block;
    }
    .flights-block.flights-block-sm table th:first-child,
    .flights-block.flights-block-sm table td:first-child {
        width: 50%;
        padding-left: 0;
    }
    .flights-block.flights-block-sm table th:last-child,
    .flights-block.flights-block-sm table td:last-child {
        width: 50%;
        padding-right: 0;
    }
    .flights-block.flights-block-sm table .flight-airport div {
        width: auto;
        white-space: normal;
    }
    .flights-block.flights-block-sm .flight-summary {
        display: block;
        text-align: center;
    }
    .flights-block.flights-block-sm .flight-summary div {
        margin-bottom: 15px;
        text-align: left;
    }
}
@media (max-width: 400px) {
    .flights-block.flights-block-sm table .flight-airport div {
        max-width: 150px;
    }
}

.share-block {
    margin: 30px;
}

.share-block a {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    color: #000101;
}

.share-block a:hover {
    text-decoration: underline;
}

.share-block a:before {
    flex-shrink: 0;
    margin-right: 10px;
    width: 24px;
    height: 26px;
    display: block;
    content: '';
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAaCAYAAACtv5zzAAABgUlEQVRIibXWwUtUURTH8Y9agpBkrURECnQjgX9Bf0DUX5AuXEcboZWbclWu/AOildAikRbtok3rdi2i0IWuTBc1mZKEWRw4wmteMvnmzhced85wz2/m3Pe791wFmcZbnKCFxxgoJT+Cz/iNj/ian5drMxsyn4JPM300q2j1F/qBSzl+ynEP3zBcm3lOhrCI/awgRB/hZcavmwr3YQ7bKXSA5zjOOJ4NXK9l/gc38S5FwjHPMJZpE7iLWxg8r/AU1iv/8A1marMacBUr+JnCH3C7idA4buBixlHiAr6kcDjjHi7UMjtwGa8qpe+kMzYz/oEnOa8Rqyn0HmttTghnXOt2jY+wm54OllJ8sTazAac7uS8flbEYqxV3xA78VXqJznrJGxkfdfuST+mZTTtx5R8b7U6HnEZM9uqoaKdnh12VsPJs5bg+LHlcV2lvONEmH+JFtw2nnfsp+CC/jwq3Yk+V6snR0YK4ugTR9MN93wvp/3VtibWP3lz02hLUL14M/AHNFHsGsLHuDAAAAABJRU5ErkJggg==');
}

@media (max-width: 400px) {
    .share-block a {
        font-size: 14px;
    }
}

.loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.loading.loading-active {
    display: flex;
}

.loading:before {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    content: '';
}

.loading .loading-window {
    position: fixed;
    max-width: 1000px;
    width: 90%;
    max-height: 90%;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.1);
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.loading .loading-body {
    background: #fff;
    border-radius: 20px;
    padding: 20px 60px;
    overflow: auto;
    box-sizing: border-box;
    position: relative;
}

.loading .loading-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.loading .loading-progress {
    height: 20px;
    border-radius: 10px;
    background: #f3f3f3;
    overflow: hidden;
}

.loading .loading-progress-bar {
    background: #ffba20;
    width: 0;
    height: 100%;
    -webkit-animation: loading 2s linear infinite;
            animation: loading 2s linear infinite;
}

@-webkit-keyframes loading {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

@keyframes loading {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

@media (max-width: 800px) {
    .loading .loading-body {
        padding: 20px;
    }
}

.modal {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    align-items: center;
    justify-content: center;
}

.modal:before {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    content: '';
}

.modal .modal-window {
    border-radius: 20px;
    position: relative;
    max-width: 95%;
    max-height: 90%;
    min-width: 400px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
    overflow: auto;
}

.modal .modal-body {
    background: #fff;
    border-radius: 20px;
    padding: 40px 100px;
    box-sizing: border-box;
    position: relative;
    font-size: 13px;
}

.modal .modal-close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    width: 21px;
    height: 21px;
    z-index: 1;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAB2UlEQVQ4ja3VTYjNYRTH8c9cTCiyUDIbZSVZ2SgpSUpThDBS5CVFmY23QnbTKG8r4yWJvJSkKJlQQlazuSvKSrEYWSiljJdm6OTc5vb/32vuNH67+5xzv/c55/zOc9uq1epCXMFWvDcxzcXHCs5hKV5i/gSQa/AOewO6BQOYh1dYUEofWxtxD1OxKKBfsCqBHXgRgXEAo2130I6z6K5k4CtW4xnm4DkWl75e1m7cwCScxCH8rtSlfcu+9GN2/sCSEmZU+3LAATyBY7VIpZA4hPV4gFl4imUlHAfQhzYcQU99sAgN/cRm3MVMPMHKuvjR7F2oG6eLgMkl5F/9ygF8x3Y8xIa0XpQ6EtbJ8ktqBg0NYyd+YA8eZWVxvisH1FCNyq9X7Uav63Iv/guoBWjEL6VvR/Ispr6tlNkiNKxyLUsfSrv15Pn1PG+oZj2dgpvoSv+uTd/25+deXM4t6mvlpu1pp67CptVU25zQeRweCzoN97Gu8CYUFT7dHyuJUzjeDDo9/diJz2n4gRJuVBeyr8PZ694idAYeJ+gTVqBawpR1FTsSHJt2JlY3BhU7HsB4PAYT/LYFYE23cvNu42C8qXHTeAsD+AHLxwmsKR7oTbl9b/73f1QHBv8AmJdqImTRS7QAAAAASUVORK5CYII=');
}

.modal .modal-title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
}

.modal .modal-subtitle {
    font-size: 14px;
    text-align: center;
    margin-bottom: 30px;
}

@media (max-width: 800px) {
    .modal .modal-body {
        padding: 40px;
    }

    .modal .modal-close {
        top: 15px;
        right: 15px;
    }
}

@media (max-width: 450px) {
    .modal .modal-window {
        min-width: unset;
    }

    .modal .modal-body {
        padding: 40px 15px;
    }

    .modal .modal-body button[type='submit'] {
        font-size: 20px;
        padding: 15px 20px;
        border-radius: 28px;
    }
}

@media (max-width: 800px) {
    .modal .modal-body {
        padding: 40px;
    }
    .modal .modal-close {
        top: 15px;
        right: 15px;
    }
}
@media (max-width: 450px) {
    .modal .modal-window {
        min-width: unset;
    }
    .modal .modal-body {
        padding: 40px 15px;
    }
    .modal .modal-body button[type='submit'] {
        font-size: 20px;
        padding: 15px 20px;
        border-radius: 28px;
    }
}

.form .form-control {
    max-width: 400px;
    margin: 5px auto;
}

.form .form-control input {
    width: 100%;
    display: block;
    font-size: 16px;
    padding: 15px 5px;
    border: 0;
    border-bottom: #a3a3a3 1px solid;
    box-sizing: border-box;
}

.form .form-control .form-error {
    visibility: hidden;
    margin-top: 5px;
    font-size: 11px;
    color: #ff0000;
}

.form .form-control-error input {
    border-color: #ff0000;
}

.form .form-control-error .form-error {
    visibility: visible;
}

.form .form-checkbox {
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
}

.form .form-checkbox label {
    font-size: 16px;
}

.form .form-submit {
    text-align: center;
    margin-top: 30px;
}

.form .form-submit button {
    min-width: 260px;
}

.form .form-links {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
}

.form .form-link + .form-link {
    margin-top: 30px;
}

.form .form-link a {
    text-decoration: none;
    font-weight: 600;
}

.form .form-link a:hover {
    text-decoration: underline;
}

.form .form-link-password {
    color: #c6c6c6;
}

.form .form-link-reg {
    color: #f99f1e;
    font-size: 18px;
}

.form .form-policy label {
    font-size: 11px;
    align-items: flex-start;
}

.form .form-policy a {
    color: #000101;
    text-decoration: underline;
}

.form .form-require {
    font-size: 11px;
    margin: 30px auto;
}

.control-input {
    font-size: 18px;
    padding: 10px 5px;
    background: #fff;
    box-sizing: border-box;
    border: 0;
    border-bottom: #a3a3a3 1px solid;
}

.control-input-big {
    padding: 15px 5px;
    font-size: 30px;
    font-weight: 500;
}

.control-button {
    display: inline-block;
    border: #bbb9b9 1px solid;
    background: #f5f5f5;
    padding: 10px 30px;
    margin: 0;
    border-radius: 5px;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    cursor: pointer;
}

.control-button:focus {
    background: #d4d3d3;
}

.control-button-active {
    background: #d4d3d3;
}

.control-button-big {
    padding: 15px 40px;
    font-size: 18px;
}

@media (max-width: 500px) {
    .control-button {
        padding: 10px 15px;
    }

    .control-button-big {
        padding: 10px 20px;
    }
}

.control {
    position: relative;
}

.controls {
    display: flex;
}

.controls > .control-button {
    margin-left: -1px;
    border-radius: 0;
}

.controls > .control-button:first-child {
    margin-left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.controls > .control-button:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.controls .control {
    position: relative;
}

.controls .control + .control {
    margin-left: -1px;
}

.controls .control .control-button {
    border-radius: 0;
}

.controls .control:first-child .control-button {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.controls .control:last-child .control-button {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.controls .control .control-button-dropdown:focus {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.control-datepicker {
    position: relative;
    cursor: pointer;
}

.control-datepicker:after {
    position: absolute;
    top: 50%;
    right: 25px;
    display: block;
    -webkit-transform: translateY(-9px) rotateZ(45deg);
            transform: translateY(-9px) rotateZ(45deg);
    width: 12px;
    height: 12px;
    border-right: #555 2px solid;
    border-bottom: #555 2px solid;
    box-sizing: border-box;
    content: '';
}

@media (max-width: 500px) {
    .control-datepicker:after {
        right: 15px;
    }
}

.control-number {
    position: relative;
}

.control-number-minus,
.control-number-plus {
    position: absolute;
    top: 50%;
    display: block;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #555;
    font-size: 18px;
    padding: 10px;
    cursor: pointer;
}

.control-input-big ~ .control-number-minus,
.control-input-big ~ .control-number-plus {
    font-size: 30px;
}

.control-number-minus {
    left: 10px;
}

.control-number-plus {
    right: 10px;
}

.control-number .control-input {
    padding-left: 40px;
    padding-right: 40px;
}

.control-number .control-input-big {
    padding-left: 50px;
    padding-right: 50px;
}

.control-checkbox {
    position: relative;
    cursor: pointer;
    font-size: 18px;
    display: inline-flex !important;
    align-items: center;
}

.control-checkbox input[type='checkbox'],
.control-checkbox input[type='radio'] {
    display: none;
}

.control-checkbox input[type='checkbox'] + i,
.control-checkbox input[type='radio'] + i {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 20px;
    height: 20px;
    border: #a3a3a3 1px solid;
    background: #f3f3f3;
    border-radius: 50%;
    box-sizing: border-box;
    margin-right: 15px;
}

.control-checkbox input[type='checkbox']:checked + i:before,
.control-checkbox input[type='radio']:checked + i:before {
    position: absolute;
    display: block;
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #f99f1e;
    content: '';
}

.button-dropdown {
    background: #d4d4d4;
    padding: 5px 10px;
    border: 0;
    border-radius: 15px;
    text-align: left;
    line-height: 1;
    position: relative;
}

.button-dropdown:after {
    display: inline-block;
    margin-left: 10px;
    position: relative;
    z-index: 11;
    -webkit-transform: translateY(-6px) rotateZ(45deg);
            transform: translateY(-6px) rotateZ(45deg);
    width: 10px;
    height: 10px;
    border-right: #555 1px solid;
    border-bottom: #555 1px solid;
    box-sizing: border-box;
    content: '';
}

.button-dropdown.active:after {
    -webkit-transform: translateY(0px) rotateZ(-135deg);
            transform: translateY(0px) rotateZ(-135deg);
}

.dropdown {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    background: #d4d4d4;
    padding: 0 10px;
    border-radius: 15px;
    line-height: 1;
    text-align: left;
}

.dropdown.open {
    display: block;
}

.dropdown-item {
    padding: 5px 0;
    border-top: #858585 1px solid;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
}

.dropdown-item:first-child {
    border-top: 0;
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 340px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0 !important;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.react-autosuggest__suggestion-match {
    color: red;
    font-weight: bold;
}

.share-popover {
    overflow: visible !important;
    max-width: 90%;
}

.share-popup {
    background: white;
    padding: 20px;
    box-shadow: 0px 0px 20px 0px rgba(90, 90, 90, 0.2);
    border-radius: 20px;
    display: flex;
}

@media (max-width: 400px) {
    .share-popup {
        flex-direction: column;
    }
    .share-item {
        margin-bottom: 15px;
    }
    .share-item:last-of-type {
        margin-bottom: 0;
    }
}

.share-item {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
}

.share-item button {
    border: none;
    padding: 0;
    background-color: unset;
}

footer {
    background: #f89d1c;
    color: #4f1515;
    padding: 15px 0;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    height: 50px;
}

footer a {
    color: #4f1515;
}

footer a:hover {
    text-decoration: underline;
}

footer .logo {
    display: block;
    margin: 0 30px;
}

.react-datepicker-wrapper {
    display: block !important;
}

.react-datepicker-popper {
    z-index: 10 !important;
}

/* Flight View */

.login-block {
    padding: 15px;
    padding-left: 40px;
    display: flex;
    align-items: flex-start;
    margin: 20px 0;
    position: relative;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.login-block ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.login-block ul li {
    margin: 12px 10px 0 0;
    padding: 0;
    font-size: 11px;
    width: calc(50% - 10px);
}

.login-block .login-link {
    display: block;
    margin-left: 10px;
    order: 3;
    display: inline-flex;
    align-items: center;
    color: #000101;
    text-decoration: none;
}

.login-block .login-link:after {
    display: block;
    margin-left: 10px;
    width: 26px;
    height: 26px;
    content: '';
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAC8UlEQVRIib2WXWiOYRjHf+/TDmRmaT4jYSvLx1ZEL1uIWlk5MEucSMPkBMm3Ew4kR8iJhgM5YNIcqBEitdmyWphmEQ44UDP1GrGo6er5P553z32/7xz519tb13Xd/+u+r88nVV9fTx6kgGVAHVANlAPFMs8AfUAb0AJ0AMO5qAJHEqMW6BZRGrgLNACr9GuQLC2bbp3xosAjLAKagI3AZb3mvWMVwl5yHJgNHAFuAzeARmAw2zDpaCpwDyjUTbscahgHzAeWA5+BW7qIkV8ErgPtQA3wyRe6IjkZ8jipBB4CP3XTTmAncAboAabLrktnh8RV5HPUpJdYnPuz5JOBR8B3hXGJZFYYs4AB4HyWfb84CsU5InS1ykk64cSwRRW2Hvid0H0DDqsoxgNfs5xt0suvAq2BSvikEu/LyVLgvsdJhHZxLEzIu8Rp3KkC9UmlwuJDCdDrkUf4obyVOBo4Bbw1H4EcPMlTwlYAYx2pi5QjCTmNuy5Qxz9wTGJ8ASY60hgp5SfjaEIYd3Wg6nnhqGNY2BY50hhz5OyDowlh3OWBZteAo45hpV0hQh9qVGXvPDrEXZxv1kXo1Kv2OJqwPfYBV/INVNSwmVFyYDgB7AJmJuTbNbZOOydiGHcm0KivcNQjcRN4A+xIyPcD5zTzcsG4+wKN+DU5jCIMy25eQl6qHOaDcbcFGvVVQFke4xnAWuBZQm47aC8wxjkRokzcLYE2oxEccsxgikbIK+AjcDah3wos1gTf4JwOOY27I1BYjgHbNFRRNR3V+LDheABYmVxmcmAz7o72kP1Pki4tTuMejsq7FWgGrmmpWdwPqnTnAheAX859Q1if7Na8tL30FFghrmZxj9hH0fp9qS1aqX2Sa2on0aut+xp4LK7GyCbb0aC63MIxAZjmUI2OBYpAj7gGfY7Qjq9SgbTrG6D0HxyYzSWdsbPG8fd7weD7CrJbbNZmtIqzRjUCm8IW1qg5reOtGVeL+DmwLspJEv/nAxL4A/TAsmOMOcA8AAAAAElFTkSuQmCC');
}

.login-block .flight-info-form {
    text-align: center;
    margin: 0 0 0 auto;
}

.login-block .flight-info-form .form-link {
    margin-top: 10px;
    font-size: 14px;
}

.login-block .flight-info-form a {
    text-decoration: none;
    font-weight: 600;
    color: #f99f1e;
    font-size: 14px;
}

.login-block .flight-info-form a:hover {
    text-decoration: underline;
}

.login-block .flight-info-form button[type='submit'] {
    font-size: 18px;
    padding: 12px 30px;
    border-radius: 24px;
    min-width: 190px;
}

@media (max-width: 800px) {
    .login-block {
        flex-direction: column;
        padding-left: 15px;
    }

    .login-block .login-link {
        margin-left: auto;
        order: 0;
    }

    .login-block .login-link:before {
        content: 'Войти';
    }

    .login-block ul {
        margin-right: 0;
    }

    .login-block .flight-info-form {
        margin: 20px auto 0;
    }
}

@media (max-width: 600px) {
    .login-block ul {
        display: block;
    }

    .login-block ul li {
        margin-right: 0;
        width: auto;
    }
}

@media (max-width: 400px) {
    .login-block .login-link {
        margin-bottom: 10px;
    }
}

.logout-block {
    margin: 20px 0;
    padding: 5px 15px;
    text-align: right;
}

.logout-block a {
    color: #000101;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
}

.logout-block a:hover {
    text-decoration: underline;
}

.logout-block a:after {
    display: block;
    margin-left: 10px;
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    content: '';
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAC40lEQVRIiZ2Wy0sXcRTFPzM/e1oJUdBjFRhIQQYRWFoUQQuRFkGiqyGD/gV7bJP2LaKMoB8URQvbhEREFJkKQkVEubGCCAKVMCuoxIk7c37N4ztj2YFZ/O69v3Pmfh/njheGIaWoeh6wBzgKtAFNQIPKZ4BxYAgYAEaALFmQ/PTLNKh67cAzEbUA94Ae4ICeHsVaVGO17Q6P4HZU9VYD/UAncBU4D7xz/pnFFuA0cAK4DZwEZtMdZYWq3gbgPlAPdAFjDiWsArYDe4Ep4A7wVbndwC3gG3CYIPzkCsWdPAV+REsQhJNUvVpdM3BB+7VEMSO3gmmJflR8PTAILANaCcLZ/B71q5NYJIF1+QjYClRyna0E1gCXUvFJ7VW9OEmE4o3vjJYrK2I4LoGNBYfH0ylsl2BarCvijLnxdYT7oo0PwqI9OQiscKJZMXt25uJjOkx9plGndW/WXSnCZqCuIJ7GvDrOw07shGn4EhgmCMuO8C/nIhZjqROOOYdNw9eNf+AUJZjSGy8E28MvJXnjbvNlKy+ddILnwJwTdTHhRGIYd5OvUzPtpBPU7kQZQt2p1yV5424o97oEj4G3wE8nE2NObrDg8vpy4XVOJoERnEs5Qhqh8mecTALjnvFl9TucdBbX9UJ5mF1d1AUtg3GP+7L4QyVFNVREmsdy4KETzcK4h3wNrVaqXqNTEsOWrFtmmce8fG6fkyGytsaIGwZ8TcYXQK9TCJuAG7KSooPjyznMdC/LitLoFfdIPCZi47sb2X0QjlL1rItTwFnZT8WRKMYHYD/wXpPXXKGDIBxMz6ObSnYAV4BdEinqpAyhDoZNgmvAKEHYTY4kHr/wSktWWaQIWrrPWspZccaJklFuTrz2P4S+y/Mm86M8SxQnWvVG3j96XA3m8ja37LoYxx8RVygWs6+XY8ARWU+NxESt/ZobWMzuVu1+manayLH/Rt8JaSz2A3IbYB8xBiN7Azz56wck8Bsa+daUDSJ8VQAAAABJRU5ErkJggg==');
}

.reserv-block {
    padding: 20px 70px;
    margin: 20px 0;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.reserv-block .reserv-h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.reserv-block .reserv-info {
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0;
    padding: 15px;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    background: #f5f5f5;
}

.reserv-block .reserv-summary {
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0;
    justify-content: space-between;
}

.reserv-block .reserv-price {
    white-space: nowrap;
}

.reserv-block .reserv-h2 a {
    color: #000101;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.reserv-block .reserv-h2 a:hover {
    text-decoration: underline;
}

.reserv-block .reserv-h2 a i {
    display: block;
    width: 25px;
    height: 25px;
    margin-right: 20px;
    border-radius: 50%;
    background: #f5f5f5;
    position: relative;
    flex-shrink: 0;
}

.reserv-block .reserv-h2 a i:before {
    position: absolute;
    left: 40%;
    top: 50%;
    display: block;
    width: 8px;
    height: 8px;
    border: #353535 1px solid;
    border-top: 0;
    border-right: 0;
    -webkit-transform: translateY(-50%) rotateZ(45deg);
            transform: translateY(-50%) rotateZ(45deg);
    content: '';
}

@media (max-width: 800px) {
    .reserv-block {
        padding: 20px 15px;
    }
}

@media (max-width: 600px) {
    .reserv-block .reserv-info .reserv-price {
        display: none;
    }

    .reserv-block .reserv-summary {
        display: flex;
    }
}

.flight-block {
    padding: 20px 70px;
    margin: 20px 0;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.flight-block:first-of-type {
    margin-top: 0;
}

.flight-block .flight-md {
    display: none;
}

.flight-block .flight-h1 {
    font-size: 28px;
    font-weight: 600;
    color: #ffa000;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.flight-block .flight-h1 i {
    display: block;
    width: 27px;
    height: 24px;
    margin-right: 10px;
    content: '';
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAYCAYAAAALQIb7AAABpUlEQVRIib2VTStEURjHf8MoaoQwjNd8AVsbH0EWsrGWppCokayVBStZeImywwdgo6SwlKxGkQViYaO8LFCjZ+bIdJ4z956p4VdTc/7nf+7/Ps85995IZgsfFoBp4BEYAfa8VlmUKcVNv1ETwDbQ4HSF4BsWy/tfDaSUo4Rh99Z4DKhVrhB8w26tsVQ6qVwh+IbdKQUmrPZ6hR0CN8CQmv3FrkyoA8aVGoCE9QBdwArQXMDqChOmgCqlFkDCrs1UDbDstjnbKMSBpFILEDVh3WZ6EBgGzsw4Zjz17uVZUuZhd1VYAWSAUyAtF7qyDBtqSTCtwE6gIxc4k9/GvyQCzEnY6z+ECU8S1qHk0pMGBmTP2q1LbwInwCfwZrRK8wJ28QD0mX15Bz4sz7P5ZU9afmVyB6OOBZ2OkB+WgHOlOpA2tuXJSUeQ0KSUHLLf60otgISVm6lV4Nhto1EpOdZ+WuSDtLHXVBf0CLQoBb5MC72JmlZchixwVbYb8Bpz4vuJSSgFFpUSgm9Y3BofABfKVaIw+xDMFxtUTNi+ORAvwCxwVHQS8A1s1EQaVkakLAAAAABJRU5ErkJggg==');
}

.flight-block2 .flight-h1 i {
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
}

.flight-block .flight-h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}

.flight-block .flight-h2 img {
    vertical-align: middle;
    margin-left: 10px;
    max-height: 50px;
}

.flight-block table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
}

.flight-block table th {
    border-bottom: #a3a3a3 1px solid;
    vertical-align: top;
    font-size: 10px;
    font-weight: 500;
    padding: 10px;
}

.flight-block table td {
    vertical-align: top;
    font-size: 15px;
    padding: 25px 10px 0;
}

.flight-block table td small {
    font-size: 12px;
}

.flight-block .flight-table-2 th,
.flight-block .flight-table-2 td {
    width: 50%;
}

.flight-block .flight-table-3 th,
.flight-block .flight-table-3 td {
    width: 33.333%;
}

.flight-block .flight-more {
    font-size: 16px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.flight-block .flight-more:after {
    flex-grow: 1;
    display: block;
    margin-left: 10px;
    height: 1px;
    background: #a3a3a3;
    content: '';
}

.flight-block .flight-more a {
    color: #000101;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.flight-block .flight-more a:after {
    display: block;
    width: 10px;
    height: 10px;
    margin-left: 10px;
    margin-top: -7px;
    -webkit-transform: rotateZ(-45deg);
            transform: rotateZ(-45deg);
    border: #353535 1px solid;
    border-right: 0;
    border-top: 0;
    cursor: pointer;
    content: '';
}

.flight-block .flight-more.flight-more-active a:after {
    -webkit-transform: rotateZ(135deg);
            transform: rotateZ(135deg);
    margin-top: 7px;
}

.flight-block .flight-more + table {
    display: none;
}

.flight-block .flight-more.flight-more-active + table {
    display: table;
}

.flight-block .gallery {
    display: flex;
    align-items: center;
}

.flight-block .gallery-slider {
    position: relative;
    margin-left: 50px;
    width: calc(100% - 40px);
}

.flight-block .gallery-slider-inner {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.flight-block .gallery-slider-inner img {
    display: block;
    width: 32%;
    height: 120px;
    object-fit: cover;
}

.flight-block .gallery-slider-image {
    object-fit: cover;
}

.flight-block .gallery-slider-slide > div {
    margin: 0 5px;
}

.flight-block .gallery-slider .carousel__back-button,
.flight-block .gallery-slider .carousel__next-button {
    border: none;
}

.flight-block .gallery-slider a {
    position: absolute;
    top: 50%;
    display: block;
    width: 10px;
    height: 10px;
    border: #353535 1px solid;
    border-top: 0;
    cursor: pointer;
}

.flight-block .gallery-slider .gallery-slider-prev {
    left: -20px;
    -webkit-transform: translateY(-50%) rotateZ(45deg);
            transform: translateY(-50%) rotateZ(45deg);
    border-right: 0;
}

.flight-block .gallery-slider .gallery-slider-next {
    right: -20px;
    -webkit-transform: translateY(-50%) rotateZ(-45deg);
            transform: translateY(-50%) rotateZ(-45deg);
    border-left: 0;
}

.flight-block .gallery > img {
    max-height: 300px;
    max-width: 500px;
}

@media screen and (max-width: 1200px) {
    .flight-block .gallery {
        flex-direction: column;
    }

    .flight-block .gallery > img {
        margin-bottom: 20px;
        width: 100%;
        max-width: unset;
    }

    .flight-block .gallery-slider {
        margin: 0 20px;
    }
}

@media screen and (max-width: 900px) {
    .flight-block .flight-lg {
        display: none;
    }

    .flight-block .flight-md {
        display: block;
    }

    .flight-block table {
        margin-bottom: 30px;
    }

    .flight-block table td {
        padding-top: 15px;
    }
}

@media (max-width: 800px) {
    .flight-block {
        padding: 20px 15px;
    }
}

@media (max-width: 500px) {
    .flight-block .gallery-slider-inner img {
        width: 100%;
    }
}

.noty-block {
    padding: 10px 70px;
    margin: 20px 0;
    border-radius: 20px;
    border: #a3a3a3 1px solid;
    display: flex;
    box-sizing: border-box;
    max-height: 130px;
    overflow: hidden;
    position: relative;
    -webkit-transition: max-height 0.5s;
    transition: max-height 0.5s;
}

.noty-block > div + div {
    margin-left: 50px;
}

.noty-block .noty-h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    padding-bottom: 5px;
    position: relative;
}

.noty-block .noty-h2:after {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    max-width: 320px;
    width: 100%;
    height: 1px;
    background: #a3a3a3;
    content: '';
}

.noty-block .noty-text {
    font-size: 9px;
    line-height: 1.4;
}

.noty-block .noty-more {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: block;
    width: 12px;
    height: 12px;
    -webkit-transform: rotateZ(-45deg);
            transform: rotateZ(-45deg);
    border: #353535 1px solid;
    border-right: 0;
    border-top: 0;
    cursor: pointer;
}

.noty-block.noty-block-expanded {
    max-height: unset;
}

.noty-block.noty-block-expanded .noty-more {
    -webkit-transform: rotateZ(135deg);
            transform: rotateZ(135deg);
    bottom: 12px;
}

@media screen and (max-width: 800px) {
    .noty-block {
        display: none;
    }
}

.order-block {
    padding: 20px 70px;
    margin: 20px 0;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.08);
}

.order-block .rules {
    display: flex;
    margin-bottom: 40px;
}

.order-block .rules-company {
    padding-left: 40px;
    position: relative;
}

.order-block .rules-company:before {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background: #f5f5f5;
    content: '';
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAPCAYAAADQ4S5JAAAA5UlEQVQokYXSvS4FYRDG8d8eW7oAlUYvolJKuIBTbKehdgNqrkEtQSsUTiORaLQkopBoRKVDolH4yMS7MTm7jid5m5n5z8c7UzVNc4QVfOlqCu9Yw1l4ayxjAS+dcO4wwj5WcRvAJx46ob/aKYlPsTRIjk08l0pzY9A67qOtOhl3cYgPvBXbE65TTJ2B2ZIp6xjTOMBjVMnAYvmtPl3ipv2lViflTVQGhtjo2UeFvTZZBq4w/0f28P1MnYwx1PZ/LfXtIb+tcaBvD9Fzq865BBBVZsqSXid0EzGDAC7KkfVda1aF829BSi/PyKlRfQAAAABJRU5ErkJggg==');
}

.order-block .rules-company + .rules-company {
    margin-left: 60px;
}

.order-block .rules-h2 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-left: -40px;
    display: flex;
    align-items: center;
}

.order-block .rules-h2:before {
    display: block;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    margin-right: 8px;
    background: #f5f5f5;
    flex-shrink: 0;
    content: '';
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAPCAYAAADQ4S5JAAAA5UlEQVQokYXSvS4FYRDG8d8eW7oAlUYvolJKuIBTbKehdgNqrkEtQSsUTiORaLQkopBoRKVDolH4yMS7MTm7jid5m5n5z8c7UzVNc4QVfOlqCu9Yw1l4ayxjAS+dcO4wwj5WcRvAJx46ob/aKYlPsTRIjk08l0pzY9A67qOtOhl3cYgPvBXbE65TTJ2B2ZIp6xjTOMBjVMnAYvmtPl3ipv2lViflTVQGhtjo2UeFvTZZBq4w/0f28P1MnYwx1PZ/LfXtIb+tcaBvD9Fzq865BBBVZsqSXid0EzGDAC7KkfVda1aF829BSi/PyKlRfQAAAABJRU5ErkJggg==');
}

.order-block .rules-link {
    font-size: 11px;
    margin-top: 5px;
}

.order-block .rules-link a {
    color: #000101;
    text-decoration: underline;
}

.order-block .continue {
    display: flex;
    justify-content: space-between;
}

.order-block .continue a {
    color: #000101;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.order-block .continue a.first-link {
    margin-right: 10px;
}

.order-block .continue a.last-link {
    margin-left: 10px;
    text-align: right;
}

.order-block .continue a:hover {
    text-decoration: underline;
}

.order-block .continue a i {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #f5f5f5;
    position: relative;
    flex-shrink: 0;
}

.order-block .continue a.first-link i {
    margin-right: 20px;
}

.order-block .continue a.last-link i {
    margin-left: 20px;
}

.order-block .continue a i:before {
    position: absolute;
    top: 50%;
    display: block;
    width: 8px;
    height: 8px;
    border: #353535 1px solid;
    border-top: 0;
    content: '';
}

.order-block .continue a.first-link i:before {
    left: 40%;
    -webkit-transform: translateY(-50%) rotateZ(45deg);
            transform: translateY(-50%) rotateZ(45deg);
    border-right: 0;
}

.order-block .continue a.last-link i:before {
    right: 40%;
    -webkit-transform: translateY(-50%) rotateZ(-45deg);
            transform: translateY(-50%) rotateZ(-45deg);
    border-left: 0;
}

.order-block .flight-info-form {
    margin-bottom: 30px;
}

@media (max-width: 800px) {
    .order-block {
        padding: 20px 15px;
    }
}

@media (max-width: 700px) {
    .order-block .rules {
        display: block;
        margin-bottom: 20px;
    }

    .order-block .rules-company:before {
        display: block;
    }

    .order-block .rules-company + .rules-company {
        margin-left: 0;
        margin-top: 20px;
    }

    .order-block .rules-h2 {
        margin-left: 0;
        margin-bottom: 5px;
    }

    .order-block .rules-h2:before {
        display: none;
    }
}

@media (max-width: 700px) {
    .order-block .continue a {
        font-size: 16px;
    }
}

.payments {
    margin-bottom: 30px;
    padding-left: 75px;
}

.payments .payments-item {
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: #c5c5c5 1px solid;
    position: relative;
}

.payments .payments-item b {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.payments .payments-item b:last-child {
    margin-bottom: 10px;
}

.payments .payments-item a {
    color: #000101;
    text-decoration: none;
    margin-left: 30px;
    display: inline-block;
}

.payments .payments-item a:hover {
    text-decoration: underline;
}

.payments .payments-item img {
    display: block;
    position: absolute;
    left: -75px;
    top: 0;
}

.payments .payments-item small {
    display: block;
    color: #868686;
    margin-bottom: 10px;
    font-size: 10px;
}

.payments .payments-item input {
    display: block;
    color: #424242;
    font-size: 12px;
    border: 0;
    background: #eee;
    border-radius: 5px;
    padding: 10px;
    flex-grow: 1;
}

@media (max-width: 500px) {
    .payments .payments-item.payments-item-link b {
        display: block;
    }

    .payments .payments-item.payments-item-link input + a {
        margin: 5px 0 0 0;
    }
}

.flight-info-form .form-control {
    margin-bottom: 20px;
}

.flight-info-form .form-checkbox {
    display: flex;
    justify-content: space-between;
}

.flight-info-form .form-checkbox label {
    font-size: 16px;
}

.flight-info-form .form-buttons {
    display: inline-block;
}

.flight-info-form .form-submit {
    text-align: center;
    margin-top: 30px;
}

.flight-info-form .form-submit button {
    min-width: 260px;
}

.flight-info-form .form-policy label {
    font-size: 11px;
    align-items: center;
}

.flight-info-form .form-policy a {
    color: #000101;
    text-decoration: underline;
}

.flight-info-form button + button {
    margin-left: 10px;
}

.flight-info-form .form-links {
    display: inline-block;
    margin-top: 10px;
}

.flight-info-form .form-links a {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    color: #000101;
}

.flight-info-form .form-links a + a {
    margin-left: 30px;
}

@media (max-width: 900px) {
    .flight-info-form .form-links a + a {
        margin-left: 0;
    }
    .flight-info-form .form-links {
        margin-top: 0;
    }
}

.flight-info-form .form-links a:hover {
    text-decoration: underline;
}

.flight-info-form .form-links a:before {
    flex-shrink: 0;
    margin-right: 10px;
    width: 33px;
    height: 33px;
    display: block;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    content: '';
}

.flight-info-form .form-links .form-links-share:before {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAaCAYAAACtv5zzAAABgUlEQVRIibXWwUtUURTH8Y9agpBkrURECnQjgX9Bf0DUX5AuXEcboZWbclWu/AOildAikRbtok3rdi2i0IWuTBc1mZKEWRw4wmteMvnmzhced85wz2/m3Pe791wFmcZbnKCFxxgoJT+Cz/iNj/ian5drMxsyn4JPM300q2j1F/qBSzl+ynEP3zBcm3lOhrCI/awgRB/hZcavmwr3YQ7bKXSA5zjOOJ4NXK9l/gc38S5FwjHPMJZpE7iLWxg8r/AU1iv/8A1marMacBUr+JnCH3C7idA4buBixlHiAr6kcDjjHi7UMjtwGa8qpe+kMzYz/oEnOa8Rqyn0HmttTghnXOt2jY+wm54OllJ8sTazAac7uS8flbEYqxV3xA78VXqJznrJGxkfdfuST+mZTTtx5R8b7U6HnEZM9uqoaKdnh12VsPJs5bg+LHlcV2lvONEmH+JFtw2nnfsp+CC/jwq3Yk+V6snR0YK4ugTR9MN93wvp/3VtibWP3lz02hLUL14M/AHNFHsGsLHuDAAAAABJRU5ErkJggg==');
}

.flight-info-form .form-links a.form-links-print:before {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAB00lEQVRYhe2Yv0rEQBDGf6OCCnLYKhaWgmDnaSOoiL1PEBBszkYQRKzOB1AEO+HAztJWLBSxtBNEn+AEG8HGxnAyMisx2ZiLJnrFfTAkl83OfJlv/8weIpJmhyISikirAAvNny+OmuCBPnwDboErT3tezALTwBDwmujrYeVMv+DA8/wnVjd/w76+PQlW/4AuCYcuCYeOIOGmSUVE9kSkWdDilGVNi1dxi1UFuAamgEtboMqGxlqwWHMYI2UX+BaSEi2wuBpfmsADsPgPo+ECmNCBOVKCBJPAjl0VvcCaWW/kvRuN35foXgzOgVFgHRgDVoEj8yyR+4/NrIwp6tuWfc8+UUYmWsAysAKc2u9GpL0R71CWHHdmDmFEggS6y7ZDx5M4NC1bBVho/rxIG5g6pWoFF7rqb8tX6KaRaFmWlMBGojU/6sAM0O8j8Z0ccdTakGU90asNpGXCB93m92NrfxShbUh5MICReLT9PQv3wGbGO3lR1fgqx4kVGEGGg3bkyCNNYHFPNBO7wBJwbA03vsHThhxRpEmzbddqpLLazaoxiz4GOvtSY7qB+WJ6qw3aVHpOfMfvMQ48xTOddipXaEpVmrMCgs9/FLQ/OJX/zf8TIrwD0rK9GSISrn4AAAAASUVORK5CYII=');
}

.flight-info-form .form-bottom {
    font-size: 10px;
    margin-top: 30px;
    line-height: 1.4;
}

.flight-info-form .form-bottom a {
    color: #000101;
    text-decoration: none;
}

.flight-info-form .form-bottom a:hover {
    text-decoration: underline;
}

@media (max-width: 1200px) {
    .flight-info-form .form-links {
        display: block;
        float: none;
        /* margin-top: 20px; */
    }
}

@media (max-width: 950px) {
    .flight-info-form .form-links {
        display: inline-flex;
        flex-direction: column;
    }
    .flight-info-form .form-links a + a {
        margin-left: 0;
    }
    .flight-info-form .form-links a {
        margin-bottom: 10px;
    }
    .flight-info-form .form-links a:last-of-type {
        margin-bottom: 0;
    }
}

@media (max-width: 600px) {
    .flight-info-form .form-control {
        flex-direction: column;
    }

    .flight-info-form .form-buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .flight-info-form button + button {
        margin: 20px 0 0 0;
    }

    .flight-info-form .form-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .flight-info-form .form-links a {
        margin: 20px 0 0 0;
    }
}

@page {
    size: A4;
}

@media print {
    body {
        overflow: hidden;
        height: unset;
        background: unset;
        margin: 1cm;
    }

    .main {
        margin-top: 0;
    }

    .gallery-slider {
        display: none;
    }

    .flight-block {
        box-shadow: none;
        margin-top: 0;
    }

    .flight-info:nth-of-type(n + 2) {
        page-break-before: always;
    }

    .flight-block .gallery > img {
        width: 100%;
        margin: 0;
    }

    .noty-block {
        border: none;
        padding: 0;
        page-break-before: always;
    }

    .noty-block .noty-text {
        font-size: 6pt;
    }

    .noty-block .noty-more {
        display: none;
    }

    /* .flight-info {
        display: inline-block;
        page-break-inside: avoid;
    } */
}

